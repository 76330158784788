export const apiRoutes = {
  auth: {
    refreshToken: 'auth/refresh-token',
    email: {
      login: 'auth/email/login',
      registrationConfirmation: 'auth/email/registration-confirmation',
      passwordChange: 'auth/email/password-change',
      passwordResetRequest: 'auth/email/password-reset-request',
      passwordReset: 'auth/email/password-reset',
    },
    google: {
      login: 'auth/google/login',
    },
  },
  translations: {
    list: 'translations',
    update: 'translations/:id',
  },
  languages: {
    locale: 'languages/locale/:locale',
    options: 'languages/options',
  },
  users: {
    get: 'users/:id',
    me: 'users/me',
    list: 'users',
    create: 'users',
    update: 'users/:id',
    delete: 'users/:id',
    selfDeleteRequest: 'users/self-delete-request',
    selfDelete: 'users/self-delete/:token',
    bulkDelete: 'users/bulk-delete',
  },
  userTokens: {
    get: 'user-tokens/:token',
  },
  userSettings: {
    updateSelf: 'user-settings/update-self',
    getSelf: 'user-settings/get-self',
    getLayoutOptions: 'user-settings/options/layout',
  },
  profile: {
    updateSelf: 'profile/update-self',
    getSelf: 'profile/get-self',
  },
  roles: {
    options: 'roles/options',
    mappedAccess: 'roles/mapped-access',
  },
  assets: {
    uploadImage: 'assets/upload-image',
    uploadPrivateFile: 'assets/upload-private-file',
    uploadPrivateFiles: 'assets/upload-private-files',
    getAsset: 'assets/owned/:id',
    downloadFile: 'assets/download-file/:id',
    getDownloadableFile: 'assets/get-downloadable-file/:id',
  },
  health: {
    version: 'health/version',
  },
  audit: {
    list: 'audit',
  },
  entityTranslations: {
    get: 'entity-translations/entity',
  },
};
